import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, iosTransitionAnimation } from '@ionic/angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { SwiperModule } from 'swiper/angular';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { ApiInterceptor } from './providers/interceptors/http.interceptor';
import { Device } from '@awesome-cordova-plugins/device/ngx';

import { IonicStorageModule } from '@ionic/storage-angular';
import { LottieModule } from 'ngx-lottie';
import { playerFactory } from './components/component-import/component-import.module';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({ declarations: [AppComponent],
    entryComponents: [],
    bootstrap: [AppComponent], imports: [IonicStorageModule.forRoot(),
        SwiperModule,
        LottieModule.forRoot({ player: playerFactory }),
        BrowserAnimationsModule,
        BrowserModule,
        IonicModule.forRoot({
            //navAnimation:iosTransitionAnimation,
            backButtonIcon: 'chevron-back',
            // swipeBackEnabled:true,
            mode: "md",
            rippleEffect: false
        }), AppRoutingModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        Device,
        Insomnia,
        AppRate,
        AppVersion,
        //  OneSignal,
        File,
        FilePath,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }


// /Users/gautam/Documents/Projects/fiverr/mangekimambi/mageKimambi_latest/Untitled.keystore
// mangekimambi