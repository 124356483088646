import { Location } from '@angular/common';
import { Component } from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';

import { AlertController, NavController, Platform, ToastController } from '@ionic/angular';
import { ApiService } from './providers/api.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Capacitor } from '@capacitor/core';
import { SubscriptionService } from './providers/subscription/subscription.service';
//import { Purchases } from "@awesome-cordova-plugins/purchases/ngx";
import { format, isBefore, parseISO } from 'date-fns';
import { Purchases } from '@revenuecat/purchases-capacitor';

import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
//4C94422F-298C-43E9-8688-C6A563BC763B


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    //  private purchases: Purchases,
    public nav: NavController,
    public platform: Platform,
    public api: ApiService,
    public toastCtrl: ToastController,
    private _location: Location,
    public alertController: AlertController,
    public appVersion: AppVersion,
    public subscription: SubscriptionService,

  ) {
  //  console.log = function() {}

  }


  public async initializeFirebase(): Promise<void> {
      initializeApp(environment.firebase);
      SplashScreen.hide();
      this.nav.navigateRoot('splash');
    //  this.nav.navigateRoot('lemon-sqeezy');
      
  }

  ngOnInit() {
    this.initializeFirebase();
    this.platform.backButton.subscribeWithPriority(5, (processNextHandler) => {
      console.log('Back press handler!');
      if (this._location.isCurrentPathEqualTo('/tabs/tab1') || this._location.isCurrentPathEqualTo('/login')) {
        // Show Exit Alert!
        console.log('Show Exit Alert!');
        this.showExitConfirm();
        processNextHandler();
      } else {
        // Navigate to back page
        console.log('Navigate to back page');
        this._location.back();
      }
    });

    document.addEventListener("screenshotTaken", async(event) => {
      console.log(event);
      this.api.reportScreenshot().subscribe(res => {
        console.log("reported screenshot", res);
      });

      if(this.api.userProfile.username == 'mk' || this.api.userProfile.username == 'mangekimambi'){
        //await PrivacyScreen.disable();
      }else{
        setTimeout(() => {
          alert("Taking screenshot of this app is against our policy 🙃! If we find more events of screenshots your account will be permanently banned 😌.");
          localStorage.clear();
          this.api.userProfile = {};
          this.api.token = '';
          this.nav.navigateRoot('/login');
        }, 300);
      }
     

    }, false);

    document.addEventListener("screenRecordingStarted", (event) => {
      console.log(event);
      this.api.reportScreenshot().subscribe(res => {
        console.log("reported screen-recording", res);
        localStorage.clear();
        this.api.userProfile = {};
        this.api.token = '';
        this.nav.navigateRoot('/login');
      });
      if(this.api.userProfile.username == 'mk' || this.api.userProfile.username == 'mangekimambi'){
        //await PrivacyScreen.disable();
      }else{
        setTimeout(() => {
          alert("Taking screenshot of this app is against our policy 🙃! If we find more events of screenshots your account will be permanently banned 😌.");
          localStorage.clear();
          this.api.userProfile = {};
          this.api.token = '';
          this.nav.navigateRoot('/login');
        }, 300);
      }
    }, false);

    document.addEventListener("screenRecordingStopped", (event) => {
      console.log(event);
      this.api.reportScreenshot().subscribe(res => {
        console.log("reported screen-recording", res);
        localStorage.clear();
        this.api.userProfile = {};
        this.api.token = '';
        this.nav.navigateRoot('/login');
      });
      if(this.api.userProfile.username == 'mk' || this.api.userProfile.username == 'mangekimambi'){
        //await PrivacyScreen.disable();
      }else{
        setTimeout(() => {
          alert("Taking screenshot of this app is against our policy 🙃! If we find more events of screenshots your account will be permanently banned 😌.");
          localStorage.clear();
          this.api.userProfile = {};
          this.api.token = '';
          this.nav.navigateRoot('/login');
        }, 300);
      }
    }, false);

  }


 

  showExitConfirm() {
    this.alertController.create({
      header: 'Really?',
      message: 'Do you want to close the app?',
      backdropDismiss: true,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();

        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

}
