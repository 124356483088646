
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { CryptoJsService } from '../cryptojs/crypto-js.service';
import { ApiService } from '../api.service';
import { NavController } from '@ionic/angular';
//import { Device } from '@awesome-cordova-plugins/device/ngx';



@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private encryption: CryptoJsService,
        public api: ApiService,
        public nav: NavController,
      //  public device: Device,
   

    ) {
        
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('type') == 'noencrypt') {
            console.log('no encrypt');

            return this.handlepostrequest(req, next);

        } else {
            req = req.clone({
                headers: req.headers
                    .set('Accept', 'application/json,*/*')
                    .set('Content-type', 'application/json')
                    .set('device_id', this.api.device_id)
                    .set('app_version', this.api.app_version),
                url:/*  this.api.baseUrl +  */req.url
            });
            return this.handlepostrequest(req, next);
        }
    }

    private handlepostrequest(req, next) {
        let encryptbody = JSON.parse(JSON.stringify(req.body));
        if (req.headers.get('type') == 'noencrypt') {
            console.log('no encrypt');
            req = req.clone({
                headers: req.headers.delete('type')
            });
            return next.handle(req);
        } else {

            if (encryptbody) {
                encryptbody = this.doencrypt(encryptbody)
            }

        }


        req = req.clone({ body: encryptbody })
        return next.handle(req).pipe(
            tap(res => {
                // console.log(res);
                if (res instanceof HttpResponse) {

                 //   console.log(res.body)

                    let encryptbody = res.body;
                    //  console.log(encryptbody)

                    if (encryptbody && encryptbody !== undefined && encryptbody !== null) {
                        encryptbody = this.dodecrypt(encryptbody)
                    }

                    //  console.log(encryptbody);

                    return of(new HttpResponse({ status: res.status, body: encryptbody }));

                }

            })
            , catchError((error: HttpErrorResponse) => {
               // console.log(error);
                if (error.status === 401) {
                    localStorage.clear();
                    this.api.userProfile = {}
                    this.api.token = '';
                    this.nav.navigateRoot('login');
                    console.log("We detected that you have logged in from another device. Please login again.");

                    let encrypted = this.dodecrypt(error.error)
                    // console.log(encrypted)
                    error = new HttpErrorResponse({ error: encrypted, headers: error.headers, status: error.status, statusText: error.statusText, url: error.url });
                    return throwError(error);
               
                }
                if (error instanceof HttpErrorResponse) {

                    console.log(error.error);

                    let encrypted = this.dodecrypt(error.error)
                    // console.log(encrypted)
                    error = new HttpErrorResponse({ error: encrypted, headers: error.headers, status: error.status, statusText: error.statusText, url: error.url });
                    console.log(error);
                    return throwError(error);

                }
                else {
                    // console.log('LOL');
                    return throwError(error);
                }

            })

        );
    }
    private dodecrypt(encryptbody) {
        // console.log(encryptbody)
        if (encryptbody instanceof Object) {
            //  console.log('is Object')
            Object.entries(encryptbody).forEach(
                ([key, value]) => {
                    if (value instanceof Array) {
                        let newarr = []
                        console.log('is Array');
                        value.forEach(element => {
                            let newobj = this.dodecrypt(element);
                            newarr.push(newobj)
                        });
                        encryptbody[key] = newarr;
                    }

                    else if (value instanceof Object) {
                        console.log('is Object')
                        let newdata = this.dodecrypt(value);
                        encryptbody[key] = newdata;
                    }
                    else {
                        //    console.log('is string');
                        let decrypted = this.encryption.decrypt(value)
                        if (decrypted) {
                            encryptbody[key] = JSON.parse(decrypted);
                        }
                        else {
                            encryptbody[key] = value
                        }
                    }


                }
            );
        }
        else if (encryptbody instanceof Array) {
            console.log('is Array')
            let newarr = []
            encryptbody.forEach(element => {
                let newobj = this.dodecrypt(element);
                newarr.push(newobj)
            });
            encryptbody = newarr;
        }
        else {
            console.log('is string')
            encryptbody = this.encryption.decrypt(encryptbody)
        }
        //  console.log(encryptbody);

        return encryptbody
    }
    private doencrypt(encryptbody) {

        // return this.encryption.encrypt(encryptbody);

        if (encryptbody instanceof Object) {
            Object.entries(encryptbody).forEach(
                ([key, value]) => {
                    if (value instanceof Array) {
                        let newarr = []
                        value.forEach(element => {
                            let newobj = this.doencrypt(element);
                            newarr.push(newobj)
                        });
                        encryptbody[key] = newarr;
                    }

                    else if (value instanceof Object) {
                        let newdata = this.doencrypt(value);
                        encryptbody[key] = newdata;
                    }

                    else {

                        let encrypted = this.encryption.encrypt(value)
                        if (encrypted) {
                            encryptbody[key] = encrypted
                        }
                        else {
                            encryptbody[key] = value
                        }
                    }


                }
            );
        }
        else if (encryptbody instanceof Array) {
            console.log('is Array')
            let newarr = []
            encryptbody.forEach(element => {
                let newobj = this.doencrypt(element);
                newarr.push(newobj)
            });
            encryptbody = newarr;
        }
        else {
            console.log('is string')
            encryptbody = this.encryption.encrypt(encryptbody)
        }
        return encryptbody
    }


}