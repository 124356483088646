import { Component, Input, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnimationController, createAnimation, IonContent, ModalController, NavController } from '@ionic/angular';
import { NgxWatermarkOptions } from 'ngx-watermark';
import { ApiService } from 'src/app/providers/api.service';
import { DataService } from 'src/app/providers/data.service';
import { PostsService } from 'src/app/providers/posts/posts.service';
import { ViewImagePage } from 'src/app/view-image/view-image.page';
import { ViewVideoPage } from 'src/app/view-video/view-video.page';
import SwiperCore, { Navigation, Pagination, SwiperOptions, Virtual, Zoom } from 'swiper';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { trigger, state, style, transition, animate } from '@angular/animations';

SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);
SwiperCore.use([Zoom]);
SwiperCore.use([Virtual]);

import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  animations: [
    trigger('expand', [
      state('void', style({ height: '0px' })),
      state('*', style({ height: '*' })),
      transition('void <=> *', animate('250ms ease-in-out')),
    ]),

    trigger('expand2', [
      state('void', style({ height: '0px' })),
      state('*', style({ height: '*' })),
      transition('void <=> *', animate('100ms ease-out')),
    ]),

  ],
})
export class PostComponent implements OnInit {
  @Input() ionContent: IonContent;
  @Input() post: any;
  public selectedOption: any;
  expanded = false;
  public config: SwiperOptions = {
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 5,
    pagination: true,
    centeredSlides: true,
    virtual: true,
    speed: 400,
  };
  public options: NgxWatermarkOptions = {
    text: this.api.userProfile.username,
    width: 120,
    height: 100,
    color: '#ff0000',
    alpha: 0.3,
    degree: -45,
    fontSize: '15px',
    textAlign: 'center',
  };

  public options3: NgxWatermarkOptions = {
    text: this.api.userProfile.username,
    width: 120,
    height: 120,
    color: '#ff0000',
    alpha: 0.3,
    degree: -45,
    fontSize: '15px',
    textAlign: 'center',
  };

  public options2: NgxWatermarkOptions = {
    text: this.api.userProfile.username,
    width: 120,
    height: 120,
    color: '#ff0000',
    alpha: 0.3,
    degree: -45,
    fontSize: '15px',
    textAlign: 'center',
  };
  public lottieOptions: AnimationOptions = {
    path: 'assets/animations/like2.json',
  };

  public lottieOptions2: AnimationOptions = {
    path: 'assets/animations/bookmark2.json',
  };

  private animationItem: AnimationItem;
  private bookmarkAnimationItem: AnimationItem;



  constructor(
    public api: ApiService,
    public modalController: ModalController,
    public postsService: PostsService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public dataService: DataService,
    private animationCtrl: AnimationController,
    public modalCtrl: ModalController,
    private ngZone: NgZone,
    public nav: NavController
  ) { }
  /*   animationCreated(animationItem: AnimationItem): void {
      this.animationItem = animationItem;
      this.animationItem.autoplay = false;
      this.animationItem.loop = false;
  
      if (this.post.liked_by.length) {
        this.ngZone.runOutsideAngular(() => {
          this.animationItem.setDirection(1);
          this.animationItem.setSpeed(3);
          this.animationItem.play();
        });
      }
    }
   */
  /*   bookmarkAnimationCreated(bookmarkAnimationItem: AnimationItem) {
      this.bookmarkAnimationItem = bookmarkAnimationItem;
      this.bookmarkAnimationItem.autoplay = false;
      this.bookmarkAnimationItem.loop = false;
  
  
      let obj = this.api.bookmarks.find(x => x.id === this.post.id)?.id;
      if (obj) {
        this.ngZone.runOutsideAngular(() => {
          this.bookmarkAnimationItem.setDirection(1);
          this.bookmarkAnimationItem.setSpeed(3);
          this.bookmarkAnimationItem.play();
        });
      }
      else {
  
      }
    } */


  ngOnInit() {
    setTimeout(() => {
      this.post.like_count = this.numFormatter(this.post.like_count);
      this.post.comment_count = this.numFormatter(this.post.comment_count);
      // this.post.published_at = this.getTime(this.post.published_at);
    }, 100);

    if (this.post.post_type == 'poll') {
     // console.log(this.post.poll_options.length);
      for (let i = 0; i < this.post.poll_options.length; i++) {
        if (this.post.poll_options[i].isSelected == true) {
          this.selectedOption = i;
        }
      }
    }

  }


  async viewImage(index) {
    const modal = await this.modalController.create({
      component: ViewImagePage,
      animated: true,
      enterAnimation: modalAnimation,
      leaveAnimation: leaveModalAnimation,
      componentProps: {
        index: index,
        images: this.post.media,
      },

      keyboardClose: true,
      showBackdrop: true,
    });

    return await modal.present();
  }

  async viewVideo(path, name) {
    const modal = await this.modalController.create({
      component: ViewVideoPage,
      componentProps: {
        path: path,
        name: name
      },
      cssClass: 'videoModal',
      keyboardClose: true,
      showBackdrop: true,

    });

    return await modal.present();
  }

  getTime(time) {

    return format(parseISO(time), 'MMM dd yyyy');

  }


  view(data) {

    if (data.isViewing) {
      this.animationCtrl.create()
        .addElement(document.getElementById(data.id))
        .duration(300)
        .fromTo('height', '200px', '0px')
        .fromTo('opacity', '1', '0').play();
    } else {
      this.animationCtrl.create()
        .addElement(document.getElementById(data.id))
        .duration(300)
        .fromTo('height', '0', '200px')
        .fromTo('opacity', '0', '1').play();
    }
    data.isViewing = !data.isViewing;

  }

  viewFull(i) {
    this.expanded = !this.expanded;
    i.view ? i.view = false : i.view = true;
  }

  async like2(i) {
    // console.log( this.animationItem.playDirection);
    if (i.liked_by.length) {
      //   console.log('unliking');
      this.ngZone.runOutsideAngular(() => {
        this.animationItem.setDirection(-1);
        this.animationItem.setSpeed(2);
        this.animationItem.play();
      });
      let data1 = {
        type: 'post',
        id: i.id,
        react: 'Dislike'
      }
      i.like = false;
      i.like_count = i.like_count - 1;
      i.liked_by.splice(i.liked_by.indexOf(this.api.userProfile.id), 1);
      this.api.like(data1).subscribe(res => {
        console.log(res);
        //   localStorage.setItem(this.selectedCategory, JSON.stringify(data));

      }, err => {
        console.log(err);
      });


    } else {
      //   console.log('liking');

      // this.animationItem.playDirection = 1;

      this.ngZone.runOutsideAngular(() => {
        this.animationItem.setDirection(1);
        this.animationItem.setSpeed(1);
        this.animationItem.play();
      });

      let data1 = {
        type: 'post',
        id: i.id,
        react: 'Like'
      }
      i.like = true;
      i.like_count = i.like_count + 1;
      i.liked_by.push(this.api.userProfile.id);
      this.api.like(data1).subscribe(res => {
        console.log(res);

        //  localStorage.setItem(this.selectedCategory, JSON.stringify(data));
      }, err => {
        console.log(err);
      });
    }
  }
  async like(i) {
    await Haptics.impact({ style: ImpactStyle.Light });
    // console.log(i);
    let data1 = {
      type: 'post',
      id: i.id,
      react: 'Like'
    }
    i.like = true;
    i.like_count = i.like_count + 1;
    i.liked_by.push(this.api.userProfile.id);
    this.api.like(data1).subscribe(res => {
      console.log(res);

      //  localStorage.setItem(this.selectedCategory, JSON.stringify(data));
    }, err => {
      console.log(err);
    });
  }

  async unlike(i) {
    await Haptics.impact({ style: ImpactStyle.Light });

    let data1 = {
      type: 'post',
      id: i.id,
      react: 'Dislike'
    }
    i.like = false;
    i.like_count = i.like_count - 1;
    i.liked_by.splice(i.liked_by.indexOf(this.api.userProfile.id), 1);
    this.api.like(data1).subscribe(res => {
      console.log(res);
      //   localStorage.setItem(this.selectedCategory, JSON.stringify(data));

    }, err => {
      console.log(err);
    });
  }
  async openComments(i) {

    this.dataService.setData('postDetails', {
      id: i.id,
      content: i.content
    });
    console.log(this.dataService.getData('postDetails'));
    this.nav.navigateForward('comments');
    // this.router.navigate(['comments/postDetails']);
  }

  bookmark2(i) {

    let obj = this.api.bookmarks.find(x => x.id === i.id)?.id;
    if (obj) {
      this.ngZone.runOutsideAngular(() => {
        this.bookmarkAnimationItem.setDirection(-1);
        this.bookmarkAnimationItem.setSpeed(3);
        this.bookmarkAnimationItem.play();
      });
      this.api.bookmarks.splice(this.api.bookmarks.indexOf(i), 1);
      localStorage.setItem('bookmarks', JSON.stringify(this.api.bookmarks));
    } else {
      this.ngZone.runOutsideAngular(() => {
        this.bookmarkAnimationItem.setDirection(1);
        this.bookmarkAnimationItem.setSpeed(1);
        this.bookmarkAnimationItem.play();
      });
      this.api.bookmarks.push(i);
      localStorage.setItem('bookmarks', JSON.stringify(this.api.bookmarks));
    }

  }

  async bookmark(i) {
    await Haptics.impact({ style: ImpactStyle.Light });

    let obj = this.api.bookmarks.find(x => x.id === i.id)?.id;
    if (obj) {
      /// console.log("already bookmarked");
    } else {
      //  console.log("bookmarked");
      this.api.bookmarks.push(i);
      localStorage.setItem('bookmarks', JSON.stringify(this.api.bookmarks));
    }
  }


  unbookmark(i) {
    this.api.bookmarks.splice(this.api.bookmarks.indexOf(i), 1);
    localStorage.setItem('bookmarks', JSON.stringify(this.api.bookmarks));
  }

  checkBookmark(i) {
    let obj = this.api.bookmarks.find(x => x.id === i.id)?.id;
    if (obj) {
      return true;
    }
    else {
      return false;
    }

  }


  numFormatter(num) {
    // console.log(num);
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
    } else if (num < 999) {
      return num; // if value < 1000, nothing to do
    }
  }

  convertUrl(url) {
    if (url.includes('youtu')) {
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        url = 'https://www.youtube.com/embed/' + match[2];
        return match ? match[2] : '';
        //   return  this.sanitizer.bypassSecurityTrustResourceUrl(url);
        //  return url;
      } else {
        //   console.log('error in url', url);
        //this.presentToast('Invalid youtube url')
      }
    } else {
      var subject = url;
      // console.log("vimeo", url);
      var result = subject.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i);
      // console.log(result);
      return result ? result[1] : '';

    }
  }

  isYoutubeUrl(url) {
    ///  console.log(url);
    if (url.includes('youtu') || url.includes('youtu.be') || url.includes('vimeo')) {
      return true;
    } else {
      return false;
    }
  }
  isVimeoUrl(url) {
    ///  console.log(url);

    if (url.includes('vimeo')) {
      return true;
    } else {
      return false;
    }
  }


  async preview(event: any, file: string) {
    const body: HTMLElement = await this.ionContent.getScrollElement();
    console.log(event, file, body.clientHeight);

    const modal = await this.modalCtrl.create({
      component: ViewImagePage,
      animated: true,
      enterAnimation: modalAnimation,
      leaveAnimation: leaveModalAnimation,
      componentProps: {
        image: file,
        position: event.clientY - event.layerY,
        viewport: (body.clientHeight / 2)
      }
    });
    return await modal.present();
  }

  getSlicedContent(content) {
    console.log('getting sliced');
    if (content) {
      return content.slice(0, 300).trim();
    } else {
      return '';
    }
  }



  selectPoll(event: any) {
    if(this.post.pollIsLive){
    this.post.isVoted = true;
   // console.log(event);
    this.post.poll_options.forEach(element => {
      element.isSelected = false;
    });
    this.post.poll_options[event.detail.value].isSelected = true;
    let data = {
      poll_id:this.post.id,
      poll_option_id:this.post.poll_options[event.detail.value].id
    }
    console.log(data);
    this.api.submitVote(data).subscribe(res=>{
      this.post.isVoted = res['data']['isVoted'];
      this.post.poll_options  = res['data']['poll_options'];
      console.log(res);
    },err=>{
      this.post.isVoted = false;
      console.log(err);
    });
  }else{
    alert("Sorry you are late to vote! Poll is expired.");
  }
}


}





const modalAnimation = (baseEl: HTMLElement) => {
  const baseAnimation = createAnimation();

  const backdropAnimation = createAnimation();
  backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

  const wrapperAnimation = createAnimation();
  wrapperAnimation.addElement(baseEl.shadowRoot.querySelector('.modal-wrapper'))
    .keyframes([
      { offset: 0, opacity: '0', transform: 'translateX(0%)' },
      { offset: 1, opacity: '0.99', transform: 'translateX(0%)' }
    ]);

  backdropAnimation.fromTo('opacity', 0.01, 0.8);
  return baseAnimation
    .addElement(baseEl)
    .easing('ease-in-out')
    .duration(300)
    .beforeAddClass('show-modal')
    .addAnimation(backdropAnimation)
    .addAnimation(wrapperAnimation)
}

const leaveModalAnimation = (baseEl: HTMLElement) => {
  return modalAnimation(baseEl).duration(300).direction('reverse');
}

