import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostComponent } from '../post/post.component';
import { IonicModule } from '@ionic/angular';
import { NgxWatermarkModule } from 'ngx-watermark';
import { SwiperModule } from 'swiper/angular';


import { LottieModule } from 'ngx-lottie';

import player from 'lottie-web';
import { NoSubscriptionComponent } from '../no-subscription/no-subscription.component';


// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}



@NgModule({
  declarations: [
    NoSubscriptionComponent,
    PostComponent,

  ],
  imports: [
    LottieModule.forRoot({ player: playerFactory }),
    CommonModule,
    IonicModule,
    NgxWatermarkModule,
    SwiperModule,
    
  ],
  exports: [
    NoSubscriptionComponent,
    PostComponent,
 
    
  ],
})
export class ComponentImportModule { }
