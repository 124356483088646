<ion-header class='ion-no-border' >
  <ion-toolbar color="dark" >
    <ion-buttons slot="start">
     <ion-button  (click)="close()" fill="clear">
      <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
     </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content >


 <div  >
  <div (click)="setVisibility()" class="banner" [ngxWatermark]="options" id="media"  #media >

  </div>
  <plyr class="player"
  (plyrLoadedData)="loadedData($event)"
  [plyrPlaysInline]="true"
  (plyrInit)="player = $event; videoInitDone()"
   (plyrPlay)="played($event)"
  (plyrEnded)="ended()"
  (plyrTimeUpdate)="timeUpdated($event)"
  [plyrTitle]="name"  [plyrOptions]="settings" [plyrSources]="videoSources" ></plyr>

  <ion-button *ngIf="isVisible" (click)="toggleSpeed()" fill="clear" size="small" expand="block" color="light"> speed {{currentSpeed}}x</ion-button>

 <!-- 
  <plyr class="player"
  *ngIf="videoType=='m3u8'"
  [plyrPlaysInline]="true"
  [plyrDriver]="hlsDriver"
  (plyrInit)="player = $event; videoInitDone()"
   (plyrPlay)="played($event)"
  (plyrEnded)="ended()"
  (plyrTimeUpdate)="timeUpdated($event)"
  [plyrTitle]="name"  [plyrOptions]="settings" [plyrSources]="videoSources" ></plyr> -->
 
<!-- <iframe *ngIf="videoType=='html5'" [src]="senitizer.bypassSecurityTrustResourceUrl('https://iframe.mediadelivery.net/embed/52928/'+path+'?autoplay=true')" loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; " allowfullscreen="false"></iframe> -->
 
<!--   <ion-button class="closeButton" fill="clear" (click)="close()">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button> -->

<!--   <div class="controls" *ngIf="isVisible ">

    <ion-grid>
      <ion-row>
        <ion-col size="2">
          <ion-buttons>
            <ion-button *ngIf="!isMute" (click)="mute()">
              <ion-icon slot="icon-only" name="volume-high"></ion-icon>
            </ion-button>

            <ion-button *ngIf="isMute" (click)="mute()">
              <ion-icon slot="icon-only" name="volume-mute"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-col>
        <ion-col size="8">
          <ion-range color="danger"   (ionKnobMoveEnd)="seeked($event)"  (ionKnobMoveStart)="seekingStart($event)" [max]="videoTime" [value]="videoCurrentTime" ></ion-range>
        </ion-col>
        <ion-col size="2">
          <ion-buttons>
            <ion-button *ngIf="!isPlaying" (click)="play()">
              <ion-icon  slot="icon-only" name="play"></ion-icon>
            </ion-button>

            <ion-button *ngIf="isPlaying" (click)="pause()">
              <ion-icon  slot="icon-only" name="pause"></ion-icon>
            </ion-button>
          </ion-buttons>
         
        </ion-col>
      </ion-row>

    </ion-grid>
  </div> -->
 </div>


<!--  <ion-fab vertical="top" horizontal="start" >
  <ion-fab-button size="small" color="danger" (click)="close()">
    <ion-icon name="arrow-back-outline"></ion-icon>
  </ion-fab-button>
</ion-fab> -->
</ion-content>
