<div class="form">
  <p style="font-size: 110px; margin-top: -1px; ">
    <ng-lottie
    width="100%"
    height="100px"
    [options]="lottieOptions"></ng-lottie>
  </p>
  <h1 style="margin-top: -100px; "><b>Oh no Pole!!</b></h1>
  <p style="margin-top:-8px;" >
    Usajili wako umekwisha
  </p>
  <p style="margin-top:-8px; font-size: 12px; color: grey;" >
    Please click on <b>Subscribe Now </b>button to continue enjoying our services.
  </p>

  <ng-lottie
  (animationCreated)="animationCreated($event)" 
  width="100%"
  height="100%"
  class="lottie"  
  [options]="lottieOptions2"></ng-lottie>


 <!--  <ion-button expand="block" (click)="refresh()">
    <b> Refresh page</b>
  </ion-button> -->

  <ion-button *ngIf="platfromType=='capacitor'" expand="block" (click)="gotoPayment()">
   <b> Subscribe via Store </b>
  </ion-button>
  <br>
  <ion-button *ngIf="platfromType!='capacitor'" expand="block" (click)="openMobileMoney()">
    <b>Subscribe via PayPal</b>
  </ion-button>

<!--   <ion-button>
    <b> Subscribe Now </b>
  </ion-button> -->
  </div>
