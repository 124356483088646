import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GestureController, ModalController } from '@ionic/angular';
import { NgxWatermarkOptions } from 'ngx-watermark';
import { ApiService } from '../providers/api.service';
import { SwiperOptions } from 'swiper';
import { ViewVideoPage } from '../view-video/view-video.page';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.page.html',
  styleUrls: ['./view-image.page.scss'],
})

export class ViewImagePage implements OnInit {
  @ViewChild('mediaSlide') mediaSlide : ElementRef;

  @Input() images: string;
  @Input() index: number;

  @Input() position: number;
  @Input() viewport: number;
/*   public config: SwiperOptions = {
    initialSlide:3,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 5,
    pagination: true,
    centeredSlides: true,
    virtual: true,
    speed: 400,
    zoom: {
      maxRatio: 5,
    },
  }; */
  slideOpts = {
   
    speed: 400,
    loop: false,
    zoom: {
      minRatio: 1,
      maxRatio: 3,
      toggle: true,
      containerClass :'swiper-zoom-container',
      zoomedSlideClass: 'swiper-slide-zoomed'
    }
  };



  translateY: number;


  public options3: NgxWatermarkOptions = {
    text: this.api.userProfile.username,
    width: 120,
    height: 120,
    color: '#ff0000',
    alpha: 0.3,
    degree: -45,
    fontSize: '15px',
    textAlign: 'center',
  };
  
  
  
  public options2: NgxWatermarkOptions = {
    text: this.api.userProfile.username,
    width: 120,
    height: 120,
    color: '#ff0000',
    alpha: 0.3,
    degree: -45,
    fontSize: '15px',
    textAlign: 'center',
  };
  constructor(

    private modalCtrl: ModalController,
    private renderer: Renderer2,
    private gestureCtrl: GestureController,
    public api:ApiService
  ) {
  }

  ngOnInit() {
    this.slideOpts['initialSlide'] = this.index;
  }

  ionViewDidEnter() {
    const gesture = this.gestureCtrl.create({
      gestureName: 'drag-image',
      el: this.mediaSlide.nativeElement,
      threshold: 70,
      direction: 'y',
      onStart: () => {
        console.log('modal closed!');
        this.dismiss();
      },
    });
  
    gesture.enable();
  }

  ngAfterViewInit() {
    
      this.translateY = this.position - (this.viewport*84/100);
    //  console.log( this.position, this.viewport*84/100, this.translateY );

      if ( this.translateY < -30 || this.translateY > 30 ) {

        this.renderer.setStyle(this.mediaSlide.nativeElement, 'transform', `translateY(${this.translateY}px) scale3d(0.99, 0.99, 1)`);
        this.renderer.setStyle(this.mediaSlide.nativeElement, 'transition', '0.5s ease-in-out');

        setTimeout(() => {
          this.renderer.removeStyle(this.mediaSlide.nativeElement, 'transform');
        }, 50);
      }
  }

  dismiss() {
    if ( this.translateY < -30 || this.translateY > 30 ) {

      setTimeout(() => {
        this.renderer.setStyle(this.mediaSlide.nativeElement, 'transform', `translateY(${this.translateY}px) scale3d(0.94, 0.94, 1)`);
      }, 50);
    }

    this.modalCtrl.dismiss();
  }

  async viewVideo(path, name) {
    const modal = await this.modalCtrl.create({
      component: ViewVideoPage,
      componentProps: {
        path: path,
        name: name
      },
      cssClass: 'videoModal',
      keyboardClose: true,
      showBackdrop: true,

    });

     await modal.present();
  }
}