

export const environment = {
  production: false,
  youtube_key: "AIzaSyAZlvdoxyrEeZGLh8mCX34lUk9i4m9egr4",
  ki: 'MOo3neRC5Cwv1BdSz0WBUlwA7y5KaLV7l6dfXbib', //'MOo3neRC5Cwv1BdSz0WBUlwA7y5KaLV7l6dfXbib',//"5LwxGN0YDchyicb2Wb0q2clyMJ2bfSEilyhD1qiU",//"MOo3neRC5Cwv1BdSz0WBUlwA7y5KaLV7l6dfXbib",
  cjk:"x1e8a1c1cf412b27ecd7a87db49f830g",
  cjiv:"g9f051fdf0e6388x",
  baseUrl: "https://chaguoletu.net",
  //baseUrl: 'http://ec2-3-234-165-147.compute-1.amazonaws.com',
  //'http://52.203.91.218',//"https://mangekimambi22.com", 
  
  firebase: {
    apiKey: "AIzaSyAZlvdoxyrEeZGLh8mCX34lUk9i4m9egr4",
    authDomain: "mangekimambi-2.firebaseapp.com",
    projectId: "mangekimambi-2",
    storageBucket: "mangekimambi-2.appspot.com",
    messagingSenderId: "1019934093528",
    appId: "1:1019934093528:web:4a600e0678e72f9d7a67ad",
    measurementId: "G-WLLVS532XK"
  },
  
  

};

