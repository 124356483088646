import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { NavController, Platform } from '@ionic/angular';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { ApiService } from 'src/app/providers/api.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-no-subscription',
  templateUrl: './no-subscription.component.html',
  styleUrls: ['./no-subscription.component.scss'],
})
export class NoSubscriptionComponent implements OnInit {
  public lottieOptions: AnimationOptions = {
    path: 'assets/animations/cry.json',
  };
  public lottieOptions2: AnimationOptions = {
    path: 'assets/animations/arrow.json',
    loop: false,
  };

  publicKey = "FLWPUBK-3464e61b534e85a921799bb80605dfc7-X";
  customerDetails = {
    name: "Gautam Mer",
    email: "placeholder@mail.com",
    phone_number: "0692432983",
  };
  customizations = {
    title: "Mange Kimambi",
    description: "Mobile App",
    logo: "https://mangekimambi22.com/logo.png",
  };
  meta = { counsumer_id: "7898xxe", consumer_mac: "kjs9s8ss7ddxxxe" };
  public platfromType:any;
  constructor(
    public nav: NavController,
    public api: ApiService,
    public plt: Platform,
  ) { 
    this.platfromType = this.plt.is('capacitor') ? 'capacitor' : 'web';
  }

  ngOnInit(
  ) { }


  

  gotoPayment() {
    this.nav.navigateForward('/payment');
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
    animationItem.setSpeed(0.2);
  }

  openMobileMoney() {
    const url = 'https://mangekimambi22.com/flutterwave/payment?order_id=' + uuidv4() +'&&token=' + localStorage.getItem('token');
      Browser.open({ 
        url:url,
        presentationStyle: 'fullscreen',
      });
      Browser.addListener('browserFinished', () => {
        console.log('browserFinished');
        window.location.reload();
      });
    //  Browser.addListener('browserFinished', (event) => {});
  //  window.open(url, '_blank');
   /*  this.api.initiateMobileMoneyPayment({
      "tx_ref": "MC-158523s09v5050e8",
      "amount": "150",
      "currency": "TZS",
      "network": "Halopesa",
      "email": "user@example.com",
      "phone_number": "0782835136",
      "fullname": "Yolande Aglaé Colbert",
      "client_ip": "154.123.220.1",
      "device_fingerprint": "62wd23423rq324323qew1",
      "meta": {
        "flightID": "213213AS"
      }
    }).subscribe((res: any) => {
      console.log(res);
    }) */
  }
  refresh() {
    window.location.reload();
  }
}
