import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataResolverService } from './resolver/data-resolver.service';
import { AuthGuard } from './providers/auth/auth.guard';

const routes: Routes = [
 /*  {
    path: '',
    redirectTo: 'tabs/tab1',
    pathMatch: 'full'
  }, */
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./account/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  },
  {
    path: 'comments',
    resolve: {
      postDetails: DataResolverService
    },
    loadChildren: () => import('./comments/comments.module').then( m => m.CommentsPageModule),
  },
  {
    path: 'subscription-details',
    loadChildren: () => import('./subscription-details/subscription-details.module').then( m => m.SubscriptionDetailsPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./account/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./account/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'view-user-profile',
    loadChildren: () => import('./view-user-profile/view-user-profile.module').then( m => m.ViewUserProfilePageModule)
  },
  {
    path: 'view-blog',
    loadChildren: () => import('./view-blog/view-blog.module').then( m => m.ViewBlogPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'blocked',
    loadChildren: () => import('./account/blocked/blocked.module').then( m => m.BlockedPageModule)
  },
  {
    path: 'update-app',
    loadChildren: () => import('./update-app/update-app.module').then( m => m.UpdateAppPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'emoji-picker',
    loadChildren: () => import('./emoji-picker/emoji-picker.module').then( m => m.EmojiPickerPageModule)
  },
 
  {
    path: 'register',
    loadChildren: () => import('./account/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./account/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'bookmarks',
    loadChildren: () => import('./bookmarks/bookmarks.module').then( m => m.BookmarksPageModule)
  },
  {
    path: 'loading',
    loadChildren: () => import('./loading/loading.module').then( m => m.LoadingPageModule)
  },
/*   {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  }, */
  {
    path: 'view-video',
    loadChildren: () => import('./view-video/view-video.module').then( m => m.ViewVideoPageModule)
  },
  {
    path: 'view-image',
    loadChildren: () => import('./view-image/view-image.module').then( m => m.ViewImagePageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./account/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'subscription-expired',
    loadChildren: () => import('./account/subscription-expired/subscription-expired.module').then( m => m.SubscriptionExpiredPageModule)
  },
  {
    path: 'media-selection',
    loadChildren: () => import('./components/media-selection/media-selection.module').then( m => m.MediaSelectionPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'view-document',
    loadChildren: () => import('./view-document/view-document.module').then( m => m.ViewDocumentPageModule)
  },
  {
    path: 'lemon-sqeezy',
    loadChildren: () => import('./account/lemon-sqeezy/lemon-sqeezy.module').then( m => m.LemonSqeezyPageModule)
  },
  {
    path: 'transaction-history',
    loadChildren: () => import('./transaction-history/transaction-history.module').then( m => m.TransactionHistoryPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
