import {PlyrDriver,
  PlyrDriverCreateParams,
  PlyrDriverDestroyParams,
  PlyrDriverUpdateSourceParams} from 'ngx-plyr';
  import Hls from 'hls.js';


 import * as Plyr from 'plyr';
//import * as Plyr from 'plyr';
 
 export class HlsjsPlyrDriver implements PlyrDriver {
 
   hls: Hls = new Hls();
  public videoElement: HTMLVideoElement;
   private loaded = false;
 
   constructor(private autoload: boolean) {

/*     this.hls.on(Hls.Events.ERROR, function (event, data) {
      
      switch (data.details) {
        case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
          
          console.log("Error loading manifest",data);
          if(data.response['code']=='404'){
            // change url to get video
      this.load(this.options.url);
           

          }
          break;
        case Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
          console.log("Timeout loading manifest");
          
          break;
        case

        Hls.ErrorDetails.MANIFEST_PARSING_ERROR:

        console.log("Error parsing manifest");  
        break;
        case Hls.ErrorDetails.LEVEL_LOAD_ERROR:
          console.log("Error loading level playlist");
          break;
        case Hls.ErrorDetails.LEVEL_LOAD_TIMEOUT:
          console.log("Timeout loading level playlist");
          break;
        case Hls.ErrorDetails.LEVEL_SWITCH_ERROR:
          console.log("Error switching level");
          break;
      
  }
}); */

   }
 
   create(params: PlyrDriverCreateParams) {
     this.hls.attachMedia(params.videoElement);
    console.log(params.videoElement);
    this.videoElement = params.videoElement;
     return new Plyr(params.videoElement, params.options);
   }
 
   updateSource(params: PlyrDriverUpdateSourceParams) {
    this.videoElement = params.videoElement;

     if (this.autoload) {
       this.load(params.source.sources[0].src);
     } else {
       // poster does not work with autoload
       params.videoElement.poster = params.source.poster;
     }
   }
 
   destroy(params: PlyrDriverDestroyParams) {
     params.plyr.destroy();
     this.hls.detachMedia();
   }
 
   getQuality(){
    return this.hls.levels;
   }


   load(src: string) {
     if (!this.loaded) {
       this.loaded = true;
       this.hls.loadSource(src);
    
      // console.log(this.hls);
     }

     this.hls.on(Hls.Events.ERROR, (event,data)=>{
      
      if(Hls.ErrorDetails.MANIFEST_LOAD_ERROR){
  
        console.log(data.url);
        this.hls.destroy();
        this.hls = new Hls();
        this.videoElement.src =data.url.replace('720p', '240p');


        this.hls.attachMedia(this.videoElement);
      // this.load(data.url.replace('720p', '240p'));
        //// play video with 240p 
       // this.hls.loadSource(data.url.replace('720p', '240p'));
         this.load(data.url.replace('720p', '240p'));
         
      }
      });
      
  }
}
 
 