import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { NavController, Platform, ToastController } from '@ionic/angular';
//import { Device } from '@awesome-cordova-plugins/device/ngx';
import { catchError, last, map, tap, throwError } from 'rxjs';
import { format, isBefore, parseISO } from 'date-fns';
import { SubscriptionService } from './subscription/subscription.service';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import { Device } from '@capacitor/device';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public baseUrl: string = environment.baseUrl + '/api/v1'; // 'https://mangekimambi22.com/api/v1';
  public url: string = environment.baseUrl;//'https://mangekimambi22.com';//'https://rvirjitechnologies.com/MK_V3_TEST';
  public ki: string = environment.ki;
  public cjk: string = environment.cjk;
  public cjiv: string = environment.cjiv;
  public device_id: any;

  public isBaseUrl: boolean = false;
  public userProfile: any = {};
  public token: any;
  public app_version: string = '3.2.0';
  public isNetwork = true;
  public toast: any;
  public bookmarks: any[] = [];
  public appSettings: any = {
    username_watermark: true
  };

  public selectedQuality: any = '240p';

  constructor(
    public http: HttpClient,
    // public device: Device,
    public nav: NavController,
    public subscription: SubscriptionService,
    public platform: Platform
  ) {
    this.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    this.token ? this.userProfile = JSON.parse(localStorage.getItem('user')) : this.userProfile = {};

    this.bookmarks = JSON.parse(localStorage.getItem('bookmarks')) ? JSON.parse(localStorage.getItem('bookmarks')) : [];
    console.log("Bookmarks:", this.bookmarks);

    localStorage.getItem('selectedQuality') ? this.selectedQuality = localStorage.getItem('selectedQuality') : this.selectedQuality = '240p';


    /*    Device.getId().then((info) => {
         this.device_id = info.identifier;
         console.log("Device ID: ", this.device_id);
     }); */

    Device.getInfo().then(res => {
      this.device_id = res.model;
      console.log("Device ID: ", this.device_id);
    })
  }



  setHeader() {
    var myHeaders = new HttpHeaders({
      'key': this.ki,
      'Authorization': 'Bearer ' + this.token,
    });
    return myHeaders;
  }
  /* 
    setHeader2() {
  
      var myHeaders = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
        'key': environment.ki,
      });
      return myHeaders;
    } */

  login(data) {
    return this.http.post(this.baseUrl + '/login', data, { headers: this.setHeader() });
  }

  getPosts(id) {
    return this.http.get(this.baseUrl + '/get_all_post_by_categories/all/all/all/' + id, { headers: this.setHeader() });
  }

  getNextPosts(url) {
    return this.http.get(url, { headers: this.setHeader() });
  }

  getCategories() {
    return this.http.get(this.baseUrl + '/get_categories', { headers: this.setHeader() });
  }

  like(data) {
    return this.http.post(this.baseUrl + '/submit_likes', data, { headers: this.setHeader() });
  }
  getEmojis() {
    return this.http.get(this.baseUrl + '/get_emoj', { headers: this.setHeader() });
  }
  getComments(id) {
    return this.http.get(this.baseUrl + '/get_post_comments/' + id, { headers: this.setHeader() });
  }
  getNextComments(url) {
    return this.http.get(url, { headers: this.setHeader() });
  }
  submitComment(data) {
    return this.http.post(this.baseUrl + '/submit_comment', data, { headers: this.setHeader() });
  }
  updateComment(data) {
    return this.http.post(this.baseUrl + '/update_comment', data, { headers: this.setHeader() });
  }

  checkUsername(username) {
    return this.http.get(this.baseUrl + '/verify_username/' + username, { headers: this.setHeader() });
  }

  register(data) {
    return this.http.post(this.baseUrl + '/register', data, { headers: this.setHeader() });
  }

  //https://mangekimambi22.com/api/v1/register
  //Je umejisajili? Tafadhali  bofya hapa kujisajili.
  getProfile() {
    return this.http.get(this.baseUrl + '/get_data', { headers: this.setHeader() });
  }

  getFoods(date) {
    //http://3.145.130.41/api/v1/get_food/2022-01-9
    return this.http.get(this.baseUrl + '/get_food/' + date, { headers: this.setHeader() });
  }

  resetPassword(data) {
    return this.http.post(this.baseUrl + '/password_recovery', data, { headers: this.setHeader() });
  }

  submitNotification(data) {
    return this.http.post(this.baseUrl + '/submit_notification_token', data, { headers: this.setHeader() });
  }

  updateProfile(data) {
    return this.http.post(this.baseUrl + '/update_user', data, { headers: this.setHeader() });
  }

  deleteAccount(id) {
    return this.http.get(this.baseUrl + '/delete_user/' + id, { headers: this.setHeader() });
  }

  getUserProfile(id) {
    return this.http.get(this.baseUrl + '/get_user_data/' + id, { headers: this.setHeader() });
  }

  getSinglePost(id) {
    return this.http.get(this.baseUrl + '/get_post_by_id/' + id, { headers: this.setHeader() });
  }

  reportScreenshot() {
    return this.http.get(this.baseUrl + '/submit_user_screenshots', { headers: this.setHeader() });
  }

  getSettings() {
    return this.http.get(this.baseUrl + '/get_app_info', { headers: this.setHeader() });
  }

  searchPost(data) {
    return this.http.post(this.baseUrl + '/search_post', data, { headers: this.setHeader() });
  }

  getNextSearchPosts(url, data) {
    return this.http.post(url, data, { headers: this.setHeader() });
  }

  getPaymentUrl() {
    return this.http.get(this.baseUrl + '/get_web_url', { headers: this.setHeader() });
  }


  getLatestPosts(post_id, cat_id) {
    return this.http.get(this.baseUrl + '/get_post_custom/' + post_id + '/' + cat_id + '/greater/all', { headers: this.setHeader() });
  }

  submitVote(data) {
    return this.http.post(this.baseUrl + '/poll', data, { headers: this.setHeader() });
  }

  getContactus() {
    return this.http.get(this.baseUrl + '/contact', { headers: this.setHeader() });

  }
  isLoggedIn() {
    return this.token ? true : false;
  }

  getPaymentHistory() {
    return this.http.get(this.baseUrl + '/payment-history', { headers: this.setHeader() });
  }


  uploadImageToBunny(data) {

    var myHeaders = new HttpHeaders({
      'type': 'noencrypt',
      'content-type': data.mime,
      'AccessKey': '7b65db0e-6ee2-4675-92906253db83-6457-4232'
    });
    return this.http.put('https://storage.bunnycdn.com/mkstoragezone/usermedia/' + data.name, data.formData, { headers: myHeaders, reportProgress: true, observe: 'events' }).pipe(catchError(this.errorMgmt));

  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }


  updateUserSubscription(data) {
    return this.http.post(this.baseUrl + '/payment-subscription', data, { headers: this.setHeader() });
  }


  async checkSubscription() {
    console.log("Checking subscription", this.userProfile);
    if (this.userProfile['is_subscribed'] == "true" || this.userProfile['is_subscribed'] == true) {
      this.subscription.isSubscriber = true;
      this.subscription.subscribedPlan.latestExpirationDate = format(parseISO(this.userProfile.end_of_subscription_date), 'MMM dd yyyy');
      this.userProfile.end_of_subscription_date = format(parseISO(this.userProfile.end_of_subscription_date), 'MMM dd yyyy');
    } else {
      this.subscription.isSubscriber = false;
      this.userProfile.end_of_subscription_date = null;
     /*  Purchases.getCustomerInfo().then().then((res) => {
        console.log("==+==+==> Customer Info: ", res);

        if (!res.customerInfo.latestExpirationDate) {
          // console.log("Expired");
          //    this.userProfile.end_of_subscription_date = null;
          //  this.subscription.isSubscriber = false;


          //! check in the user profile:

          this.getProfile().subscribe(res => {
            console.log(res['user']);
            this.userProfile = res['user'];
            localStorage.setItem('user', JSON.stringify(res['user']));

            if (this.userProfile.end_of_subscription_date) {
              console.log("Not Expired from our api");

              this.subscription.isSubscriber = true;
              this.subscription.subscribedPlan.latestExpirationDate = format(parseISO(this.userProfile.end_of_subscription_date), 'MMM dd yyyy');
              this.userProfile.end_of_subscription_date = format(parseISO(this.userProfile.end_of_subscription_date), 'MMM dd yyyy');
            } else {
              console.log("Expired from our api");
              this.subscription.isSubscriber = false;
              this.userProfile.end_of_subscription_date = null;
            }
          }, err => {
            console.log(err);
          });
          //! end of checking in user profile

          //this.removeUserSubscription();
        } else {
          //console.log("Not Expired",isBefore(parseISO(res.customerInfo.latestExpirationDate), parseISO(new Date().toISOString())));
          if (isBefore(parseISO(res.customerInfo.latestExpirationDate), parseISO(new Date().toISOString()))) {
            console.log("Expired from revenueCat");
            this.userProfile.end_of_subscription_date = null;
            this.subscription.isSubscriber = false;



            //! check in the user profile:
            this.getProfile().subscribe(res => {
              console.log(res['user']);
              this.userProfile = res['user'];
              localStorage.setItem('user', JSON.stringify(res['user']));

              if (this.userProfile.end_of_subscription_date) {
                console.log("Not Expired from our api");

                this.subscription.isSubscriber = true;
                this.subscription.subscribedPlan.latestExpirationDate = format(parseISO(this.userProfile.end_of_subscription_date), 'MMM dd yyyy');
                this.userProfile.end_of_subscription_date = format(parseISO(this.userProfile.end_of_subscription_date), 'MMM dd yyyy');
              } else {
                console.log("Expired from our api");
                this.subscription.isSubscriber = false;
                this.userProfile.end_of_subscription_date = null;
              }
            }, err => {
              console.log(err);
            });
            //! end of checking in user profile



          } else {
            console.log("Not Expired from revenueCat");
            this.subscription.isSubscriber = true;
            this.subscription.subscribedPlan.latestExpirationDate = format(parseISO(res.customerInfo.latestExpirationDate), 'MMM dd yyyy');
            this.userProfile.end_of_subscription_date = format(parseISO(res.customerInfo.latestExpirationDate), 'MMM dd yyyy');
          }
        }

      }, err => {
        this.getProfile().subscribe(res => {
          console.log(res['user']);
          this.userProfile = res['user'];
          localStorage.setItem('user', JSON.stringify(res['user']));
          // this.checkSubscription();

          if (this.userProfile.end_of_subscription_date) {
            if (isBefore(parseISO(this.userProfile.end_of_subscription_date), parseISO(new Date().toISOString()))) {
              console.log("Expired");
              this.userProfile.end_of_subscription_date = null;
              this.subscription.isSubscriber = false;
              //  this.removeUserSubscription();
            } else {
              // console.log("Not Expired");
              this.subscription.isSubscriber = true;
              this.subscription.subscribedPlan.latestExpirationDate = format(parseISO(this.userProfile.end_of_subscription_date), 'MMM dd yyyy');
            }
          } else {
            this.subscription.isSubscriber = false;
            this.userProfile.end_of_subscription_date = null;
            // this.removeUserSubscription();

          }
        }, err => {
          console.log(err);
        });
      }); */
    }


  }

  initiateMobileMoneyPayment(data) {
    var myHeaders = new HttpHeaders({
      'type': 'noencrypt',
      // 'content-type': data.mime,
      //  'AccessKey': '7b65db0e-6ee2-4675-92906253db83-6457-4232'
    });
    return this.http.post('https://api.flutterwave.com/v3/charges?type=mobile_money_tanzania', data, { headers: myHeaders });
  }
  removeUserSubscription() {
    this.subscription.isSubscriber = false;
    this.http.post(this.baseUrl + '/payment-cancel', {
      username: this.userProfile.username
    }, { headers: this.setHeader() }).subscribe((res) => {
      console.log("Subscription removed: ", res);
    });
  }
  //! Youtube API
  /*   getYoutubeVideos() {
      return this.http.get('https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&channelId=' + this.youtubeId + '&maxResults=10&key=' + environment.youtube_key);
    } */
}
