<ion-card class="post" [ngxWatermark]="api.appSettings.username_watermark?options:options2">
  <ion-toolbar class="author">
    <ion-avatar slot="start">
      <img class="logo" src='assets/logo.png'>
    </ion-avatar>
    <ion-label>
      <h2>Mange Kimambi <img class="verificationLogo" src='assets/verification.png'>
      </h2>
    </ion-label>
    <ion-label slot="end">
      <p class="time">{{post.date_reader}}</p>
    </ion-label>

  </ion-toolbar>


  <swiper [config]="config" #slideWithNav *ngIf="post.post_type!='poll'">
    <ng-template swiperSlide *ngFor="let media of post.media; let ind = index">

      <div class="watermark-container" *ngIf="media.type!='Video'" (click)='viewImage(ind)'>
        <div class="watermark" [ngxWatermark]="api.appSettings.username_watermark?options3:options2">

        </div>

        <img class="postImage" *ngIf="media.type!='Video'" [src]="media.file_path" />
      </div>

      <div *ngIf="media.type=='Video'" (click)='viewVideo(media.file_path,post.name)'>
        <img class="postImage" [src]="'assets/video_image.png'" />

      </div>
      <!--       <plyr *ngIf="media.type=='Video'" style="display: block;" plyrTitle="Video" [plyrOptions]="settings" [plyrPlaysInline]="true" [plyrSources]="media.videoSources"  ></plyr>
 -->
    </ng-template>
  </swiper>




  <ion-list *ngIf="post.post_type=='poll'">
    <ion-radio-group (ionChange)="selectPoll($event)" [value]="selectedOption" >
      <div *ngFor="let option of post.poll_options; let ind = index"
        [class]="option.isSelected?'poll-option-selected':'poll-option'">
        <ion-item lines="none" [disabled]="post.isVoted && !option.isSelected">
          <ion-radio [value]="ind"></ion-radio>

          <ion-label class="name" style="margin-left: 10px;">{{ option.options}}</ion-label>
          <ion-label class="percentage" slot="end" *ngIf="post.isVoted">{{option.percentage}}%</ion-label>

        </ion-item>
        <ion-progress-bar [value]="post.isVoted?option.percentage/100:0"></ion-progress-bar>
      </div>
    </ion-radio-group>
    <ion-label color="danger" *ngIf="post.isVoted">
      You already voted for this poll !
    </ion-label>
  </ion-list>


  <div class="content">
    <p class="time">{{post.published_at | slice:0:10}}</p>
    <p style="color: black;"><b>{{api.userProfile.username}}</b></p>
    <p class="title" style="color: black;">
      {{post.name}}
    </p>
  </div>

  <ion-card-content *ngIf="post.content">

    <p [@expand] style="color: black; font-weight: bold;" [innerHTML]='sanitizer.bypassSecurityTrustHtml(post.content)'
      [class]="post.view?'':'desc'"></p>
    <div class="ion-text-center">
      <ion-button [fill]='expanded?"outline":"solid"' (click)="viewFull(post)"
        style="height: 30px; --border-radius: 30px;">
        <ion-label><span *ngIf='!post.view'>Read More 👇</span> <span *ngIf='post.view'>Read Less 👆</span> </ion-label>
        <!--         <ion-icon *ngIf='!post.view' slot="end" name="chevron-down-outline"></ion-icon>
          <ion-icon *ngIf='post.view' slot="end" name="chevron-up-outline"></ion-icon> -->

      </ion-button>
    </div>
  </ion-card-content>
  <ion-toolbar mode='md'>

    <ion-buttons slot="start">
      <!--       <ng-lottie
    
      (click)='like2(post)'
      width="70px"
      height="80px"
      [options]="lottieOptions" (animationCreated)="animationCreated($event)"></ng-lottie>
      <span style="margin-left:-15px; font-size: 17px;">{{post.like_count}}</span> -->
      <ion-button style="margin-left: 10px;" *ngIf='post.liked_by.length' color='danger' (click)='unlike(post)'>
        <ion-icon class="liked" slot='start' src="assets/icon/like2.svg"></ion-icon>
        <span style="margin-left:0px; font-size: 16px;"> {{post.like_count}}</span>
      </ion-button>
      <ion-button style="margin-left: 10px;" *ngIf='!post.liked_by.length' (click)='like(post)'>
        <ion-icon slot='start' src="assets/icon/like.svg"></ion-icon>
        <span style="margin-left:0px; font-size: 16px;"> {{post.like_count}}</span>

      </ion-button>
      <ion-button style="margin-left: 5px;" (click)='openComments(post)'>
        <ion-icon slot='start' src="assets/icon/comment.svg"></ion-icon>
        <span style="margin-left:0px; font-size: 16px;">{{post.comment_count}}</span>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button style="margin-right: 10px;" (click)="bookmark(post)" *ngIf="!checkBookmark(post)">
        <ion-icon slot='icon-only' size="small" src="assets/icon/bookmark.svg"></ion-icon>
      </ion-button>

      <ion-button style="margin-right: 10px;" class="bookmarked" (click)="unbookmark(post)" *ngIf="checkBookmark(post)">
        <ion-icon slot='icon-only' size="small" src="assets/icon/bookmark2.svg"></ion-icon>
      </ion-button>

      <!--       <ng-lottie
    
      (click)='bookmark2(post)'
      width="50px"
      height="60px"
      [options]="lottieOptions2" (animationCreated)="bookmarkAnimationCreated($event)"></ng-lottie> -->

    </ion-buttons>
  </ion-toolbar>
</ion-card>