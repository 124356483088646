import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CryptoJsService {
  private secureKey: string;
  private secureIV: string;

  constructor(
    public api: ApiService
  ) {
    this.secureKey = api.cjk
    this.secureIV = api.cjiv
  }

  decrypt(value: any) {
   // console.log("Decryption-->",value);
    if (!value) {
      return value;
    }else{
      //return value;
      var key = CryptoJS.enc.Utf8.parse(this.api.cjk);

      var iv = CryptoJS.enc.Utf8.parse(this.api.cjiv);
  
      var decrypted = CryptoJS.AES.decrypt(value, key, {
        iv: iv, mode: CryptoJS.mode.CBC
      });
  
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
   
  }
  encrypt(value: any) {
  //  console.log("Encrypting-->",value);
    if (!value) {
      return value;
    }else{
    //  return value;

      value = typeof value === "string" ? value : JSON.stringify(value);

      var key = CryptoJS.enc.Utf8.parse(this.api.cjk);

      var iv = CryptoJS.enc.Utf8.parse(this.api.cjiv);
  
      value = CryptoJS.enc.Utf8.parse(value);
  
      var encrypted = CryptoJS.AES.encrypt(value, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      });
  
      return encrypted.toString();
    }
    
  }

}