<ion-content>
  <ion-item lines="none">
    <ion-icon name="close" color="dark" slot="end" (click)="dismiss()" style="font-size: 30px; "></ion-icon>
  </ion-item>
  <div class="zoom-box">
    <ion-slides pager="false" [options]="slideOpts">
      <ion-slide *ngFor="let image of images">
    
        <div *ngIf="image['type']!='Video'" class="swiper-zoom-container">
          <div  #mediaSlide id="mediaSlide" class="watermark" [ngxWatermark]="api.appSettings.username_watermark?options3:options2">
       
          </div>
          <img class="card-img" src="{{image['file_path']}}"/>
        </div>

        <div *ngIf="image['type']=='Video'">
    
          <img (click)="viewVideo(image['file_path'],'Video')"  class="card-img" [src]="'assets/video_image.png'" />
        </div>

      </ion-slide>
    </ion-slides>
  </div>
<!-- 
    <div class="zoom-box">
    <swiper [config]="config" #slideWithNav [initialSlide]="index">
      <ng-template swiperSlide *ngFor="let media of images">
        <div  *ngIf="media['type']!='Video'" >
          <div  #mediaSlide id="mediaSlide" class="watermark" [ngxWatermark]="api.appSettings.username_watermark?options3:options2">  </div>

          <div class="swiper-zoom-container">

          <img  class="card-img" *ngIf="media['type']!='Video'"
          [src]="media['file_path']" />
          </div>
        </div>
        
        <div *ngIf="media['type']=='Video'">
    
          <img (click)="viewVideo(media['file_path'],'Video')"  class="card-img" [src]="'assets/video_image.png'" />
        </div>
      
      </ng-template>
    </swiper>
    </div> 
   </div>
  -->


</ion-content>