import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  public products: any[] = [];
  public selectedPlan: any;
  public isLoading = false;
  public isSubscriber: boolean = false;
  public subscribedPlan: any = {};
  constructor(
    public platform: Platform,
    public http: HttpClient,

  ) {

    
  }




}
